import { Grid as MUIGrid, GridProps } from "@mui/material";

type Props = GridProps;

export const Grid = (props: Props) => {
  return (
    <MUIGrid
      {...props}
      sx={{
        "&.MuiGrid-container + .MuiGrid-container": {
          mt: 2,
        },
        "&.MuiGrid-item > *": {
          mt: 0,
        },
        "&.MuiGrid-item > *:last-child": {
          mb: 0,
        },
      }}
    />
  );
};
