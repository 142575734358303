import { Box, Stack } from "@mui/material";
import { Custom, Vector } from "components";
import { useTheme } from "contexts";
import { SocialConfig } from "assets/data";

type Props = {
  showLabel?: boolean;
  items: string[];
  size?: "small" | "medium" | "large" | undefined;
};

export const Social = ({ showLabel = false, ...props }: Props) => {
  const { theme } = useTheme();

  const iconSize: number =
    {
      small: 16,
      medium: 24,
      large: 32,
    }[props.size as string] || 32;

  return (
    <>
      <Stack
        direction="row"
        spacing={showLabel ? theme.spacing.xl : theme.spacing.sm}
      >
        {props.items.map((key: string) => {
          const item = SocialConfig[key];
          return showLabel ? (
            <Box
              key={key}
              component="a"
              target="_blank"
              href={item.href}
              sx={{ textDecoration: "none" }}
            >
              <Stack spacing={theme.spacing.sm} alignItems="center">
                <Vector.Icon
                  name={key}
                  size={iconSize}
                  fill={theme.color.font.color}
                />
                <Custom.Typography
                  size={theme.font.xxs}
                  color={theme.color.font.color}
                >
                  {item.label}
                </Custom.Typography>
              </Stack>
            </Box>
          ) : (
            <Custom.IconButton
              key={key}
              aria-label={item.label}
              onClick={() => window.open(item.href, "_blank")}
            >
              <Vector.Icon
                name={key}
                size={iconSize}
                fill={theme.color.font.color}
              />
            </Custom.IconButton>
          );
        })}
      </Stack>
    </>
  );
};
