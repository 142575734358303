import React from "react";
import { Chip as MUIChip, ChipProps, SxProps, Box } from "@mui/material";
import { useTheme } from "contexts";
import { Custom } from "components";

type Props = Omit<ChipProps, "bgColor" | "color" | "sx"> & {
  selected?: boolean;
  color?: string; // Custom color prop
  bgColor?: string; // Custom background color prop
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  sx?: SxProps; // Allow additional styling overrides
};

export const Chip = React.forwardRef<HTMLElement, Props>(
  (
    {
      endIcon,
      sx = {},
      selected = false,
      color,
      bgColor,
      label,
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    const { theme } = useTheme();

    const isFilled = props.variant === "filled";
    const isOutlined = props.variant === "outlined";

    const defaultProps = isOutlined
      ? selected
        ? {
            color: color || theme.color.accent.color,
            borderColor: bgColor || theme.color.accent.color,
          }
        : {
            color: color || theme.color.font.color,
            borderColor: bgColor || theme.color.font.color,
          }
      : selected
      ? {
          color: color || theme.color.accent.text,
          bgcolor: bgColor || theme.color.accent.color,
        }
      : {
          color: color || theme.color.primary.textAccent,
          bgcolor: bgColor || theme.color.primary.accent,
        };

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      if (onClick) onClick(event);
    }

    return (
      <MUIChip
        ref={ref}
        component="div"
        clickable={!!onClick}
        sx={{
          ...defaultProps,
          "&:hover": {
            bgcolor: defaultProps.bgcolor,
          },
          "&:active": {
            boxShadow: "none",
          },
          ...sx,
        }}
        onClick={handleClick}
        label={
          <Box display="flex" alignItems="center">
            <Custom.Typography {...defaultProps} size={theme.font.xs}>
              {label}
            </Custom.Typography>
            {endIcon && (
              <Custom.Typography
                {...defaultProps}
                sx={{ pl: theme.spacing.xs }}
              >
                {endIcon}
              </Custom.Typography>
            )}
          </Box>
        }
        {...props}
      >
        {children}
      </MUIChip>
    );
  }
);
