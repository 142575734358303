import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { Custom, Item } from "components";
import { useApp, useTheme } from "contexts";
import { WorkConfig, WorkFilterConfig } from "assets/data";
import { WorkObjType } from "types";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { HObject } from "helpers";

type Props = {
  href?: string;
};

export const Portfolio = (props: Props) => {
  const navigate = useNavigate();

  const { t } = useApp();
  const { theme } = useTheme();

  const ITEMS_PER_PAGE = 6;

  const [page, setPage] = useState<number>(1);
  const [projects, setProjects] = useState<WorkObjType[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);

  const filteredProjects = useMemo(() => {
    if (selectedFilter === 0) return projects;
    const selectedKey = Object.keys(WorkFilterConfig)[selectedFilter];
    return projects.filter((project) => project.filters.includes(selectedKey));
  }, [selectedFilter, projects]);

  const paginatedProjects = useMemo(() => {
    return filteredProjects.slice(0, page * ITEMS_PER_PAGE);
  }, [filteredProjects, page]);

  const hasMore = useMemo(() => {
    return paginatedProjects.length < filteredProjects.length;
  }, [paginatedProjects, filteredProjects]);

  useEffect(() => {
    const activeProjects = Object.values(WorkConfig).filter(
      (project) => project.active
    );
    setProjects(activeProjects);

    const defaultFilterIndex = Object.keys(WorkFilterConfig).findIndex(
      (key) => WorkFilterConfig[key].active
    );
    setSelectedFilter(defaultFilterIndex >= 0 ? defaultFilterIndex : 0);
  }, []);

  function handleFilterChange(index: number) {
    setSelectedFilter(index);
    setPage(1);
  }

  function handleButtonClick() {
    props.href ? navigate(props.href) : setPage(page + 1);
  }

  return (
    <Box id="portfolio" mb={theme.spacing.xl}>
      <Stack
        direction="row"
        flexWrap="wrap"
        mb={theme.spacing.xl}
        gap={theme.spacing.md}
        justifyContent="center"
      >
        {Object.keys(WorkFilterConfig).map((key: string, index: number) => (
          <Custom.Button
            key={key}
            size="large"
            variant="contained"
            secondary={selectedFilter !== index}
            onClick={() => handleFilterChange(index)}
          >
            {HObject.getProperty(WorkFilterConfig, [key, "name"])}
          </Custom.Button>
        ))}
      </Stack>
      <Grid container spacing={theme.spacing.xxl}>
        {paginatedProjects.map((project, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <motion.div
              style={{ height: "100%" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Item.Project item={project} />
            </motion.div>
          </Grid>
        ))}
        {hasMore && (
          <Grid item xs={12} textAlign="center">
            <Custom.Button
              size="large"
              onClick={handleButtonClick}
              variant={props.href ? "text" : "contained"}
            >
              {props.href ? t.message.view_more : t.message.load_more}
            </Custom.Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
