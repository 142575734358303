import { Common } from "components";

type Props = {};

export const Work = (props: Props) => {
  return (
    <>
      <Common.Work />
    </>
  );
};
