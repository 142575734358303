import { Common, Shared } from "components";

type Props = {};

export const Work = (props: Props) => {
  return (
    <Common.Section id="work">
      <Shared.Portfolio />
    </Common.Section>
  );
};
