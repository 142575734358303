import { createContext, useContext } from "react";

type IndexerContextType = {
  registerIndex: (id: string) => void;
  indexes: string[];
};

export const IndexerContext = createContext<IndexerContextType | null>(null);

export const useIndexerContext = (): IndexerContextType => {
  const context = useContext(IndexerContext);
  if (!context) {
    throw new Error("useIndexerContext must be used within an Indexer");
  }
  return context;
};
