import React from "react";
import { Box, SxProps } from "@mui/material";
import { useTheme } from "contexts";

type Props = {
  sx?: SxProps;
  children: React.ReactNode;
};

export const Footnote = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Box
      id="footnote"
      mt={theme.spacing.xxxl}
      py={theme.spacing.md * 2}
      bgcolor={theme.color.background.accent}
      sx={props.sx}
    >
      {props.children}
    </Box>
  );
};
