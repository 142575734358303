
enum EnumTheme {
  Light = 1,
  Dark = 2,
}

enum EnumFeedback {
  Success = "success",
  Error = "error",
  Info = "info"
}

enum EnumResponse {
  Success = 200,
  Error = 500,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  None = 0,
}

enum EnumLayout {
  Default = "default",
  Page = "page",
  Project = "project",
}

export {
  EnumTheme,
  EnumLayout,
  EnumResponse,
  EnumFeedback
}