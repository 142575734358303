import React from "react";
import { Grid, Box } from "@mui/material";
import { GalleryType } from "types";
import { useTheme } from "contexts";
import { Shared } from "components";
import { motion } from "framer-motion";

interface Props {
  columns: number;
  images: GalleryType[];
}

export const Gallery = (props: Props) => {
  const { theme } = useTheme();

  const distributedColumns = Array.from(
    { length: props.columns },
    () => [] as GalleryType[]
  );

  props.images.forEach((item: GalleryType, index) => {
    const columnIndex = index % props.columns;
    distributedColumns[columnIndex].push(item);
  });

  return (
    <Grid container spacing={theme.spacing.sm}>
      {distributedColumns.map((column: GalleryType[], colIndex: number) => (
        <Grid item xs={12} sm={6} md={12 / props.columns} key={colIndex}>
          {column.map((image: GalleryType, imgIndex: number) => (
            // <img src={image.src} />
            <Shared.Image
              key={imgIndex}
              src={image.src}
              alt={image.alt}
              style={{
                width: "100%",
                verticalAlign: "middle",
                marginTop: theme.spacing.sm * theme.spacing.factor,
              }}
            />
          ))}
        </Grid>
      ))}
    </Grid>
  );
};
