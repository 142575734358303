import { createContext, useCallback, useEffect, useState } from "react";

type Section = {
  id: string;
  title: string;
  ref: React.RefObject<HTMLDivElement>;
  parentId?: string;
};

type SectionContextType = {
  sections: Section[];
  registerSection: (section: Section) => void;
  activeSectionId: string | null;
  setActiveSectionId: (id: string | null) => void;
};

export const SectionContext = createContext<SectionContextType>({
  sections: [],
  registerSection: () => {},
  activeSectionId: null,
  setActiveSectionId: () => {},
});

export const SectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sections, setSections] = useState<Section[]>([]);
  const [activeSectionId, setActiveSectionId] = useState<string | null>(null);

  const registerSection = useCallback((section: Section) => {
    if (!section.title) return;
    setSections((prev) => [...prev, section]);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let currentSectionId: string | null = null;

        // Iterate over all observed entries
        entries.forEach((entry) => {
          const sectionId = entry.target.id;

          if (entry.isIntersecting) {
            // If the section is in view, set it as the active section
            currentSectionId = sectionId;
          }
        });

        if (currentSectionId !== null) {
          setActiveSectionId(currentSectionId);
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    // Observe each section in the document
    sections.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Cleanup the observer on component unmount
    return () => {
      sections.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sections]);

  return (
    <SectionContext.Provider
      value={{ sections, registerSection, activeSectionId, setActiveSectionId }}
    >
      {children}
    </SectionContext.Provider>
  );
};
