import { Box, BoxProps } from "@mui/material";
import { useTheme } from "contexts";

type Props = BoxProps & {
  outline?: boolean;
};

export const HighlightedBox = ({ outline = false, ...props }: Props) => {
  const { theme } = useTheme();

  return (
    <Box
      sx={{
        height: 1,
        my: theme.spacing.xxxl,
        border: outline ? 1 : undefined,
        borderRadius: theme.border.square,
        py: outline ? theme.spacing.xl : theme.spacing.sm,
        px: outline ? theme.spacing.xl : theme.spacing.lg,
        borderColor: outline ? theme.color.border.accent : undefined,
        backgroundColor: outline ? undefined : theme.color.secondary.color,
        "& > *": {
          mt: 0,
        },
        "& > *:last-child": {
          mb: 0,
        },
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
};
