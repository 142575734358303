import React from "react";
import { Box } from "@mui/material";

type Props = {
  src: string; // URL of the video
  title?: string; // Title for the iframe (optional, default: "Video Player")
  allowFullScreen?: boolean; // Allow fullscreen mode (optional, default: true)
};

export const Embedded = ({
  src,
  title = "Video Player",
  allowFullScreen = true,
}: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        paddingTop: "56.25%", // 16:9 Aspect Ratio (height/width = 9/16 * 100)
      }}
    >
      <Box
        component="iframe"
        src={src}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={allowFullScreen}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          border: "none",
        }}
      />
    </Box>
  );
};
