import { Box, SxProps } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { SectionContext } from "contexts/components";
import { Shared } from "components";
import { useTheme } from "contexts";
import { HObject } from "helpers";

type Props = {
  id: string;
  sx?: SxProps;
  title?: string;
  hidden?: boolean;
  parentId?: string;
  fullWidth?: boolean;
  children: React.ReactNode;
};

export const Section = ({
  id,
  title = "",
  hidden = false,
  fullWidth = false,
  parentId,
  children,
  sx = {},
}: Props) => {
  const { layout, theme } = useTheme();

  const sectionRef = useRef<HTMLDivElement>(null);
  const { registerSection } = useContext(SectionContext);

  const normalizedId = id ? id.replaceAll(/_/g, "-") : "";

  useEffect(() => {
    registerSection({ id, title, ref: sectionRef, parentId });
  }, [id, title, registerSection, parentId]);

  return (
    <Box
      id={id}
      ref={sectionRef}
      component="section"
      sx={{
        m: 0,
        width: 1,
        maxWidth: !fullWidth
          ? HObject.getProperty(theme.component.layout.section, [layout])
          : undefined,
        px: fullWidth ? 0 : theme.spacing.lg,
        ...sx,
      }}
      className={`section-container ${normalizedId}-container`}
    >
      <Box className="section-content">
        <Box position="relative" className="main-container">
          {!hidden && title && (
            <Box className="section-header">
              <Shared.Header title={title} />
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  );
};
