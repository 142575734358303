import { ReactNode, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { Custom } from "components";
import { useTheme, ComponentContext } from "contexts";

type Props = {
  id: string;
  title: string;
  children?: ReactNode;
};

export const Indexer = ({ id, title, children }: Props) => {
  const { theme } = useTheme();
  const { registerIndex, indexes } = ComponentContext.useIndexerContext();

  useEffect(() => {
    registerIndex(id);
  }, [id]);

  const activeIndex = indexes.findIndex((index: string) => index === id);

  return (
    <Box id={id}>
      <Stack
        width={1}
        direction="row"
        display="inline-flex"
        gap={theme.spacing.md}
        flexWrap={{ xs: "wrap" }}
      >
        {indexes.map((index: string, i: number) => {
          return (
            <Box
              key={index}
              component="div"
              sx={{
                textAlign: "center",
                whiteSpace: "nowrap",
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "center",
                height: theme.spacing.default,
                color: theme.color.font.color,
                minWidth: theme.spacing.default,
                borderRadius: theme.border.radius,
                backgroundColor: theme.color.input.color,
                ...(activeIndex === i && {
                  border: 2,
                  px: theme.spacing.lg,
                  backgroundColor: "transparent",
                  borderColor: theme.color.font.color,
                }),
              }}
            >
              <Custom.Typography weight="medium">
                {activeIndex === i ? `${i + 1}.` : i + 1}
              </Custom.Typography>{" "}
              {activeIndex === i && (
                <Custom.Typography
                  weight="medium"
                  letterSpacing={2}
                  ml={theme.spacing.sm}
                  textTransform="uppercase"
                >
                  {title}
                </Custom.Typography>
              )}
            </Box>
          );
        })}
      </Stack>
      {children}
    </Box>
  );
};
