import React, { createContext, useContext } from "react";
import { SessionType } from "types";
import { Constants, Store } from "utils";

interface ProvidedValueType {
  isAuthenticated: boolean;
  session: SessionType;
  setSession: (session: SessionType) => void;
  t: any;
  locale: string;
  locales: string[];
  setLocale: (language: string) => void;
}

const initialState = {
  locale: "EN",
  translations: Constants.LOCALE_STRINGS[Constants.REGIONS.EN],
  locales: Object.keys(Constants.REGIONS) as string[],
  session: Store.sessionStorage.get() as SessionType,
};

export const AppContext = createContext<ProvidedValueType>({
  isAuthenticated: false,
  session: initialState.session,
  setSession: () => {},
  t: initialState.translations,
  locale: initialState.locale,
  locales: initialState.locales,
  setLocale: () => {},
});

interface Props {
  initLocale: string;
  children?: React.ReactNode;
}

export const AppProvider = React.memo<Props>(
  ({ initLocale, children }: Props) => {
    const [session, setSession] = React.useState<SessionType>(
      initialState.session
    );
    const [locale, setLocale] = React.useState<string>(initLocale);

    const setSessionCallback = React.useCallback(
      (newSession: SessionType | null) => {
        setSession((currentSession: SessionType | null) => {
          const session = {
            ...currentSession,
            ...newSession,
          } as any;
          if (newSession === null) {
            Store.sessionStorage.clear();
          } else {
            Store.sessionStorage.set({ ...session });
          }
          return Store.sessionStorage.get();
        });
      },
      []
    );

    const isAuthenticated = session ? session.isAuthenticated : false;

    const MemoizedValue = React.useMemo(() => {
      const value: ProvidedValueType = {
        locale,
        t: Constants.LOCALE_STRINGS[locale],
        setLocale,
        locales: initialState.locales,
        isAuthenticated,
        session,
        setSession: setSessionCallback,
      };
      return value;
    }, [locale, isAuthenticated, session, setSessionCallback]);

    return (
      <AppContext.Provider value={MemoizedValue}>
        {children}
      </AppContext.Provider>
    );
  }
);

export const useApp = () => useContext(AppContext);
