import "assets/scss/components/item_activity.scss";

import { Box, Paper, Stack } from "@mui/material";
import { Custom, Shared, Vector } from "components";
import { useApp, useTheme } from "contexts";
import { ActivityType } from "types";
import { Constants } from "utils";

// icons
import { BusinessRounded, CalendarMonthRounded } from "@mui/icons-material";
import { ServiceConfig } from "assets/data";

type Props = {
  item: ActivityType;
  head: boolean;
  tail: boolean;
  hasCalculation?: boolean;
};

export const Activity = ({ hasCalculation = false, ...props }: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  // const isWork = props.item.is === "work";
  const isFeature = props.item.is === "feature";
  const isEducation = props.item.is === "education";

  const borderRadius = theme.border.radius * theme.border.factor;

  return (
    <Box className="activity-item">
      <Paper
        variant="outlined"
        className="content"
        sx={{
          borderRadius: 0,
          backgroundColor: "transparent",
          borderColor: theme.color.border.color,
          borderTopLeftRadius: props.head ? borderRadius : undefined,
          borderTopRightRadius: props.head ? borderRadius : undefined,
          borderBottomLeftRadius: props.tail ? borderRadius : undefined,
          borderBottomRightRadius: props.tail ? borderRadius : undefined,
        }}
      >
        <Stack spacing={theme.spacing.sm}>
          <Stack
            direction="row"
            alignItems="flex-start"
            gap={theme.spacing.sm}
            justifyContent="space-between"
            flexWrap={{
              xs: "wrap",
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={theme.spacing.sm}
            >
              {isEducation && (
                <Vector.Icon name="education" fill={theme.color.accent.color} />
              )}
              <Stack
                direction="row"
                alignItems="center"
                spacing={theme.spacing.sm}
              >
                <Custom.Typography
                  size={theme.font.sm}
                  weight={theme.font.medium}
                >
                  {props.item.as}
                </Custom.Typography>
                {isFeature && props.item.href && (
                  <Box component="a" target="_blank" href={props.item.href}>
                    <Vector.Icon
                      name="external_url"
                      size={theme.font.sm}
                      fill={theme.color.font.color}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={theme.spacing.sm}
              flexWrap={{
                xs: "wrap",
              }}
            >
              {props.item.services &&
                props.item.services.map((key: string) => (
                  <Shared.Service
                    key={key}
                    icon={key}
                    size="small"
                    item={ServiceConfig[key]}
                  />
                ))}
              <Custom.Chip selected={isEducation} label={props.item.type} />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            sx={{ opacity: 0.7 }}
            justifyContent="space-between"
            color={theme.color.font.color}
            gap={{
              xs: theme.spacing.xs,
            }}
            flexWrap={{
              xs: "wrap",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing.sm}
            >
              {!isFeature && <BusinessRounded />}
              <Custom.Typography size={theme.font.xs}>
                {props.item.at} {Constants.SEPARATOR} {props.item.in}
              </Custom.Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing.sm}
            >
              <CalendarMonthRounded />
              {!isFeature && (
                <Custom.Typography size={theme.font.xs}>
                  {props.item.from} {Constants.SEPARATOR}{" "}
                  {props.item.to ? props.item.to : t.label.present}{" "}
                </Custom.Typography>
              )}
              {isFeature && (
                <Custom.Typography size={theme.font.xs}>
                  {props.item.from}
                </Custom.Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};
