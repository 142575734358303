import React, { useState } from "react";
import { Box, Tab, Tabs as MUITabs, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "contexts";
import { Custom } from "components";
import { HObject } from "helpers";

interface TabItem {
  label: string;
}

interface Props {
  tabs: TabItem[];
}

export const Tabs = ({ tabs }: Props) => {
  const { theme } = useTheme();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  function handleChange(_: React.SyntheticEvent, newValue: number) {
    setSelectedTab(newValue);
  }

  return (
    <>
      <MUITabs
        value={selectedTab}
        onChange={handleChange}
        textColor="inherit"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          "& + .MuiBox-root": {
            mt: 0,
          },
          ".MuiTab-root": {
            textTransform: "none",
            fontSize: theme.font.sm,
            color: theme.color.font.color,
            "&.Mui-selected": {
              fontWeight: theme.font.bold,
              color: theme.color.accent.color,
            },
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            disableRipple
            label={tab.label}
            sx={{
              mt: 0,
              pl: 0,
              pr: theme.spacing.xl,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        ))}
      </MUITabs>

      <Box sx={{ position: "relative" }}>
        <motion.div
          key={selectedTab}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <Custom.Typography
            variant="h4"
            lineHeight={1.8}
            weight={theme.font.light}
            color={theme.color.font.color}
          >
            {HObject.getProperty(tabs, [selectedTab, "content"])}
          </Custom.Typography>
        </motion.div>
      </Box>
    </>
  );
};
