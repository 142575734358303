import { BrowserRouter, Route, Routes } from "react-router-dom";

import { DefaultLayout, EmptyLayout, PageLayout, ProjectLayout } from "layouts";
import { Error, Page } from "components";
import { About, Home, Project, Work } from "pages";
import { Routes as Router } from "utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <BrowserRouter>
      <Page.ScrollToTop />
      <Routes>
        <Route path={Router.home} element={<DefaultLayout />}>
          <Route index element={<Home />} />
        </Route>

        <Route element={<PageLayout />}>
          <Route path={Router.about} element={<About />} />
          <Route path={Router.work} element={<Work />} />
        </Route>

        <Route element={<ProjectLayout />}>
          <Route path={Router.project} element={<Project />} />
        </Route>

        <Route path="*" element={<EmptyLayout />}>
          <Route index element={<Error.Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
