import { Box } from "@mui/material";
import { useTheme } from "contexts";

export const HorizontalLine = () => {
  const { theme } = useTheme();

  return (
    <Box
      component="div"
      sx={{
        border: 0,
        borderTop: 1,
        height: "1px",
        display: "block",
        my: theme.spacing.xxxl,
        borderTopColor: theme.color.border.color,
      }}
    />
  );
};
