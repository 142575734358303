import { Stack, SxProps } from "@mui/material";
import { useTheme } from "contexts";
import { Custom } from "components";

type Props = {
  sx: SxProps;
  startAt?: number;
  items?: string[];
  ordered?: boolean;
};

export const BulletPoint = ({
  items = [],
  startAt = 1,
  ordered = false,
  ...props
}: Props) => {
  const { theme } = useTheme();

  return (
    <>
      <Stack
        spacing={theme.spacing.xxs}
        component={ordered ? "ol" : "ul"}
        start={ordered ? startAt : undefined}
        sx={{
          pl: theme.spacing.xxl,
          ...props.sx,
        }}
      >
        {items.map((item: string, index: number) => (
          <Custom.Typography
            key={index}
            component="li"
            variant="body2"
            color={theme.color.font.color}
          >
            {item}
          </Custom.Typography>
        ))}
      </Stack>
    </>
  );
};
