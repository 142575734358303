import { Custom } from "components";
import { useApp, useTheme } from "contexts";
import { HString } from "helpers";
import { Stack } from "@mui/material";

// icons
import { FavoriteRounded } from "@mui/icons-material";

type Props = {
  center?: boolean;
};

export const Signature = ({ center = false }: Props) => {
  const { theme } = useTheme();
  const { locale, t } = useApp();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={theme.spacing.xs}
      flexWrap={{ xs: "wrap" }}
      justifyContent={center ? "center" : undefined}
    >
      {t.footer.prefix && (
        <Custom.Typography
          weight={theme.font.light}
          color={theme.color.font.color}
        >
          {HString.interpolate(t.footer.prefix, {
            year: new Date().toLocaleDateString(locale, {
              year: "numeric",
            }),
          })}
        </Custom.Typography>
      )}
      <FavoriteRounded sx={{ color: theme.color.status.error.color }} />
      {t.footer.suffix && (
        <Custom.Typography
          weight={theme.font.light}
          color={theme.color.font.color}
        >
          {t.footer.suffix}
        </Custom.Typography>
      )}
    </Stack>
  );
};
