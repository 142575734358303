import { useTheme } from "contexts";
import {
  Typography as MUITypography,
  SxProps,
  TypographyProps,
} from "@mui/material";

type Props = Omit<TypographyProps, "sx"> & {
  sx?: SxProps;
  href?: string;
  size?: number;
  align?: "center" | "right" | "left" | "justify";
  weight?:
    | "thin"
    | "extraLight"
    | "light"
    | "normal"
    | "medium"
    | "semiBold"
    | "bold"
    | "extraBold"
    | "black";
};

export const Typography = ({
  href,
  size,
  align,
  weight,
  variant,
  children,
  color,
  sx = {},
  component: customComponent,
  ...props
}: Props) => {
  const { theme } = useTheme();

  // Default component based on variant or custom prop
  const component =
    customComponent ??
    (variant?.startsWith("h") || variant?.startsWith("body") ? "div" : "span");

  // Check if children contain HTML tags
  const isHtml = typeof children === "string" && /<.*>/.test(children);

  // Default text color based on variant
  const textColor = variant?.startsWith("body2")
    ? theme.color.font.accent
    : theme.color.font.color;

  return (
    <MUITypography
      {...props}
      sx={{
        fontSize: size,
        textAlign: align,
        fontWeight: weight === "normal" ? undefined : weight,
        ...sx, // Allow for additional styling overrides
      }}
      href={href}
      component={component}
      variant={variant}
      color={color ?? textColor}
      dangerouslySetInnerHTML={
        isHtml ? { __html: children as string } : undefined
      }
    >
      {!isHtml ? children : undefined}
    </MUITypography>
  );
};
