import { useTheme } from "contexts";

// icons
import { FormatQuoteRounded } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { Custom } from "components";

type Props = {
  content: string;
  author: string;
};

export const Quote = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Box px={theme.spacing.xl} py={theme.spacing.xxl}>
      <FormatQuoteRounded
        sx={{ fill: theme.color.font.color, width: 48, height: 48 }}
      />
      <Stack spacing={theme.spacing.md}>
        <Custom.Typography variant="h3">{props.content}</Custom.Typography>
        <Box
          sx={{ width: 50, height: 3, backgroundColor: theme.color.font.color }}
        />
        <Custom.Typography variant="body2">{props.author}</Custom.Typography>
      </Stack>
    </Box>
  );
};
