import "assets/scss/components/timeline.scss";

import { Box } from "@mui/material";
import { useTheme } from "contexts";
import { Item, Shared } from "components";
import { TimelineType } from "types";
import { motion } from "framer-motion";

type Props = {
  expanded: boolean;
  items: TimelineType[];
};

export const Timeline = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Box
      className="timeline"
      sx={{
        "--timeline-line-color": theme.color.border.color,
      }}
    >
      {props.items.map((item: TimelineType, i: number) => (
        <Item.Timeline
          key={i}
          to={item.to}
          from={item.from}
          text={item.text}
          expanded={props.expanded}
        >
          {item.items && (
            <motion.div
              style={{ height: "100%" }}
              initial={{ opacity: 0 }}
              variants={{
                hidden: { opacity: 0, y: 10 },
                visible: { opacity: 1, y: 0 },
              }}
              animate={{
                opacity: 1,
                transition: {
                  staggerChildren: 0.3,
                },
              }}
            >
              <Shared.List items={item.items} />
            </motion.div>
          )}
        </Item.Timeline>
      ))}
    </Box>
  );
};
