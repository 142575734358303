import { Box, Stack } from "@mui/material";
import { Custom } from "components";
import { useTheme } from "contexts";

type Props = {
  indicator: number | string | "circle";
  hasBackground?: boolean;
  content: string;
};

export const List = ({ hasBackground = false, indicator, content }: Props) => {
  const { theme } = useTheme();

  return (
    <Stack
      direction="row"
      my={theme.spacing.xl}
      alignItems="flex-start"
      spacing={theme.spacing.md}
    >
      <Box
        component="div"
        textAlign="center"
        px={theme.spacing.sm}
        borderRadius={theme.border.circle}
        py={indicator === "circle" ? theme.spacing.sm : theme.spacing.xxs}
        bgcolor={
          hasBackground
            ? theme.color.primary.accent
            : theme.color.secondary.color
        }
      >
        {indicator === "circle" ? (
          <Box
            component="div"
            width={5}
            height={5}
            borderRadius={theme.border.circle}
            bgcolor={theme.color.font.color}
          />
        ) : (
          <Custom.Typography
            size={theme.font.xs}
            weight={theme.font.medium}
            color={
              hasBackground
                ? theme.color.primary.textAccent
                : theme.color.font.color
            }
          >
            {indicator}
          </Custom.Typography>
        )}
      </Box>
      <Custom.Typography lineHeight={1.5} pt={theme.spacing.xxs}>
        {content}
      </Custom.Typography>
    </Stack>
  );
};
