import React, { useContext, useEffect, useState } from "react";
import { SectionContext } from "contexts/components";
import { Backdrop, Box, Fab, Stack } from "@mui/material";
import { Custom } from "components";
import { useTheme } from "contexts";
import { motion } from "framer-motion";

// icons
import {
  CloseRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  MoreVertRounded,
} from "@mui/icons-material";

export const Navigation = () => {
  const { theme } = useTheme();
  const { sections, activeSectionId } = useContext(SectionContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [expandedSections, setExpandedSections] = useState<Set<string>>(
    new Set()
  );

  // Automatically expand current section's ancestors when activeSectionId changes
  useEffect(() => {
    if (activeSectionId) {
      const parentIds = new Set<string>();
      let currentSection = sections.find(
        (section) => section.id === activeSectionId
      );

      while (currentSection?.parentId) {
        parentIds.add(currentSection.parentId);
        currentSection = sections.find(
          (section) => section.id === currentSection?.parentId
        );
      }

      setExpandedSections(new Set([activeSectionId, ...parentIds]));
    } else {
      setExpandedSections(new Set());
    }
  }, [activeSectionId, sections]);

  // Toggle section expansion
  const toggleExpand = (id: string) => {
    setExpandedSections((prev) =>
      prev.has(id)
        ? new Set([...prev].filter((item) => item !== id))
        : new Set([...prev, id])
    );
  };

  // Render the section hierarchy recursively
  const renderSections = (parentId?: string): React.ReactNode => {
    const filteredSections = sections.filter(
      (section) => section.parentId === parentId
    );

    if (filteredSections.length === 0) return null;

    return filteredSections.map(({ id, title, ref }) => {
      const isActive = activeSectionId === id;
      const hasChildren = sections.some((section) => section.parentId === id);
      const isExpanded = expandedSections.has(id);

      return (
        <React.Fragment key={id}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={theme.spacing.sm}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              const { y } = ref.current?.getBoundingClientRect() as any;
              setIsMobileMenuOpen(false);
              window.scrollBy(0, y - 100);
              if (hasChildren) toggleExpand(id);
            }}
          >
            {isActive && (
              <motion.div
                style={{ height: "100%" }}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Box
                  width={5}
                  height={25}
                  component="div"
                  bgcolor={theme.color.accent.color}
                />
              </motion.div>
            )}
            <Custom.Typography
              sx={{ whiteSpace: "nowrap" }}
              weight={isActive ? theme.font.semiBold : theme.font.light}
            >
              {title}
            </Custom.Typography>
            {/* {hasChildren && (
              <Box>
                {isExpanded ? (
                  <ExpandLessRounded fontSize="small" />
                ) : (
                  <ExpandMoreRounded fontSize="small" />
                )}
              </Box>
            )} */}
          </Stack>
          {hasChildren && (
            <Stack pl={theme.spacing.xl} spacing={theme.spacing.sm}>
              {renderSections(id)}
            </Stack>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {/* Desktop Navigation */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 99,
          display: {
            xs: "none",
            lg: "flex",
          },
          position: "fixed",
          alignItems: "center",
          px: theme.spacing.xxxl,
        }}
      >
        <Stack spacing={theme.spacing.sm}>{renderSections()}</Stack>
      </Box>

      {/* Mobile FAB */}
      <Fab
        size="small"
        color="primary"
        onClick={() => setIsMobileMenuOpen((prev) => !prev)}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
          zIndex: 1102,
          position: "fixed",
          right: theme.spacing.default,
          bottom: theme.spacing.default,
        }}
      >
        {isMobileMenuOpen ? <CloseRounded /> : <MoreVertRounded />}
      </Fab>

      {/* Mobile Navigation Menu */}
      <Backdrop
        open={isMobileMenuOpen}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
          zIndex: 1101,
          position: "fixed",
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
        }}
        onClick={() => setIsMobileMenuOpen(false)} // Close when backdrop is clicked
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          onClick={(e) => e.stopPropagation()} // Prevent closing on content click
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()} // Prevent closing on content click
            sx={{
              width: 1,
              height: 1,
              overflowY: "auto",
              "& > .MuiStack-root": {
                top: "50%",
                left: "50%",
                position: "absolute",
                transform: "scale(1.25) translate(-50%, -50%)",
              },
            }}
          >
            <Stack spacing={theme.spacing.sm}>{renderSections()}</Stack>
          </Box>
        </motion.div>
      </Backdrop>
    </>
  );
};
