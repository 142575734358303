import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { Custom, Shared } from "components";
import { useTheme } from "contexts";
import { WorkObjType } from "types";
import { useNavigate } from "react-router-dom";
import { Routes } from "utils";
import { HString } from "helpers";
import { motion } from "framer-motion";

// icons
import { CircleRounded, LockRounded } from "@mui/icons-material";

type Props = {
  item: WorkObjType;
};

export const Project = (props: Props) => {
  const navigate = useNavigate();

  const { theme } = useTheme();

  const circle = (
    <CircleRounded sx={{ width: 4, color: theme.color.font.accent }} />
  );

  return (
    <Paper elevation={0} sx={{ bgcolor: "transparent" }}>
      <Box component="div">
        <Stack spacing={theme.spacing.md}>
          <motion.div
            whileHover={{ scale: 1.05 }} // Scale up on hover
            transition={{ type: "spring", stiffness: 300, damping: 20 }} // Smooth spring effect
            style={{
              width: "100%",
              height: "auto",
              paddingTop: "100%",
              // paddingTop: "56.25%",
              position: "relative",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: theme.color.white,
              borderRadius: theme.border.square * theme.border.factor,
            }}
          >
            <motion.img
              src={`assets/${props.item.href}/thumb.png`}
              alt={props.item.name}
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
                objectFit: "cover",
                position: "absolute",
                borderRadius: theme.border.square * theme.border.factor,
              }}
              onClick={() =>
                navigate(
                  HString.interpolateURL(Routes.project, {
                    projectName: props.item.href,
                  })
                )
              }
            />
          </motion.div>
          <Box component="div">
            <Stack spacing={theme.spacing.sm}>
              <Stack
                direction="row"
                spacing={theme.spacing.sm}
                justifyContent="space-between"
              >
                <Custom.Typography
                  size={theme.font.md}
                  weight={theme.font.bold}
                  className="o-ellipsis one"
                  color={theme.color.font.color}
                >
                  {props.item.name}
                </Custom.Typography>
                {props.item.protected && (
                  <LockRounded sx={{ fill: theme.color.font.accent }} />
                )}
              </Stack>
              <Custom.Typography
                size={theme.font.sm}
                color={theme.color.font.accent}
                weight={theme.font.light}
                className="o-ellipsis two"
              >
                {props.item.short_description}
              </Custom.Typography>
              <Stack direction="row" flexWrap="wrap" gap={theme.spacing.xs}>
                {props.item.tags.map((item: string, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <Custom.Chip
                        label={item}
                        size="small"
                        variant="outlined"
                      />
                      {i !== props.item.tags.length - 1 && circle}
                    </React.Fragment>
                  );
                })}
              </Stack>
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                gap={theme.spacing.xs}
              >
                {props.item.points.map((item: string, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <Custom.Typography
                        size={theme.font.xs}
                        weight={theme.font.semiBold}
                        color={theme.color.font.color}
                      >
                        {item}
                      </Custom.Typography>
                      {i !== props.item.points.length - 1 && circle}
                    </React.Fragment>
                  );
                })}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};
