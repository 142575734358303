/* eslint-disable quotes */
import type { Color, ColorContrast, ColorSchema, Theme } from "themes/interfaces";
import { BaseBorder, BaseColor, BaseComponent, BaseFont, BaseSpacing } from "themes/base";
import { Enums } from "utils";

export const Light: Theme = {
  id: Enums.EnumTheme.Light,
  font: BaseFont,
  color: {
    ...BaseColor,
    theme: { color: "#E8E8E8" } as ColorSchema,
    border: { color: "#E4E9EC" } as ColorContrast,
    shadow: { color: "0 3px 10px 0 #D0D0D0" } as ColorContrast,
    overlay: { color: "rgba(0, 0, 0, 0.5)" } as ColorContrast,
    input: { color: "#F5F5F5", accent: "#DBDBDB" } as ColorSchema,
    font: { color: "#000000", accent: "#404040" } as ColorContrast,
    background: { color: "#FFFFFF", accent: "#F1FDF8", textAccent: "#404040" } as ColorSchema,
    primary: { color: "#B7E2DC", accent: "#202020", text: "#404040", textAccent: "#FFFFFF" } as ColorSchema,
    accent: { color: "#79C6BB", text: "#FFFFFF" } as ColorSchema,
    secondary: { color: "#F8F8F8", text: "#404040" } as ColorSchema,
    disabled: { color: "#F8F8F8", text: "#A6A6A6" } as ColorSchema,
  } as Color,
  border: BaseBorder,
  spacing: BaseSpacing,
  component: BaseComponent,
};
