import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "contexts";
import { Custom } from "components";

// icons
import {
  FilterCenterFocusRounded,
  CloseFullscreenRounded,
  ZoomInRounded,
  ZoomOutRounded,
} from "@mui/icons-material";

type Props = {
  src: string;
  alt?: string;
  href?: string;
  align?: string;
  clickable?: boolean;
  style?: React.CSSProperties;
};

export const Image = ({
  src,
  alt,
  style,
  align,
  clickable = true,
  href,
}: Props) => {
  const { theme } = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [origin, setOrigin] = useState<any>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  // Zoom and Pan states
  const [scale, setScale] = useState<number>(1);
  const [translate, setTranslate] = useState<any>({ x: 0, y: 0 });
  const [dragging, setDragging] = useState<boolean>(false);
  const [dragStart, setDragStart] = useState<any>({ x: 0, y: 0 });

  const iconButton = {
    backgroundColor: theme.color.secondary.color,
  };

  function handleImageClick(event: React.MouseEvent<HTMLImageElement>) {
    const rect = event.currentTarget.getBoundingClientRect();
    setOrigin({
      x: rect.left,
      y: rect.top,
      width: rect.width,
      height: rect.height,
    });
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
    setScale(1); // Reset zoom
    setTranslate({ x: 0, y: 0 }); // Reset translation
  }

  const zoomIn = () => setScale((prev) => Math.min(prev + 0.2, 3)); // Max zoom 3x
  const zoomOut = () => setScale((prev) => Math.max(prev - 0.2, 1)); // Min zoom 1x

  function handleDragStart(event: React.MouseEvent | React.TouchEvent) {
    if (scale > 1) {
      const { clientX, clientY } =
        "touches" in event ? event.touches[0] : event;
      setDragging(true);
      setDragStart({
        x: clientX - translate.x,
        y: clientY - translate.y,
      });
    }
  }

  function handleDrag(event: React.MouseEvent | React.TouchEvent) {
    if (dragging) {
      const { clientX, clientY } =
        "touches" in event ? event.touches[0] : event;
      setTranslate({
        x: clientX - dragStart.x,
        y: clientY - dragStart.y,
      });
    }
  }

  function handleDragEnd() {
    setDragging(false);
  }

  function resetPosition() {
    setTranslate({ x: 0, y: 0 });
  }

  // Disable scroll when the popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Re-enable scroll
    }
    return () => {
      document.body.style.overflow = ""; // Cleanup in case the component is unmounted
    };
  }, [isOpen]);

  const renderComponent = () => (
    <motion.img
      src={src}
      alt={alt}
      style={{
        maxWidth: "100%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        cursor: clickable ? "zoom-in" : undefined,
        borderRadius: theme.border.square * theme.border.factor,
        ...style,
      }}
      onClick={clickable && !href ? handleImageClick : undefined}
    />
  );

  return (
    <Box
      className="MuiImage-root"
      sx={{ mt: align === "bottom" ? "auto !important" : undefined }}
    >
      {href && (
        <Box component="a" target="_blank" href={href}>
          {renderComponent()}
        </Box>
      )}
      {!href && renderComponent()}

      {clickable && !href && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{
                x: origin.x,
                y: origin.y,
                width: origin.width,
                height: origin.height,
                opacity: 0,
              }}
              animate={{
                x: 0,
                y: 0,
                width: "100vw",
                height: "100vh",
                opacity: 1,
              }}
              exit={{
                x: origin.x,
                y: origin.y,
                width: origin.width,
                height: origin.height,
                opacity: 0,
              }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              style={{
                top: 0,
                left: 0,
                zIndex: 1300,
                display: "flex",
                position: "fixed",
                alignItems: "center",
                justifyContent: "center",
                cursor: dragging ? "grabbing" : "grab",
                background: theme.color.background.color,
              }}
              onMouseDown={handleDragStart}
              onMouseMove={handleDrag}
              onMouseUp={handleDragEnd}
              onMouseLeave={handleDragEnd}
              onTouchStart={handleDragStart}
              onTouchMove={handleDrag}
              onTouchEnd={handleDragEnd}
              // Click outside of the image to close
              onClick={(e) => {
                if (e.target === e.currentTarget) {
                  handleClose();
                }
              }}
            >
              {/* Zoom Controls */}
              <Box
                sx={{
                  zIndex: 1400,
                  display: "flex",
                  position: "absolute",
                  gap: theme.spacing.sm,
                  flexDirection: "column",
                  top: theme.spacing.default,
                  right: theme.spacing.default,
                }}
              >
                <Custom.IconButton
                  sx={iconButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                  }}
                >
                  <CloseFullscreenRounded />
                </Custom.IconButton>
                <Custom.IconButton
                  sx={iconButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    zoomIn();
                  }}
                >
                  <ZoomInRounded />
                </Custom.IconButton>
                <Custom.IconButton
                  sx={iconButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    zoomOut();
                  }}
                >
                  <ZoomOutRounded />
                </Custom.IconButton>
                <Custom.IconButton
                  sx={iconButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    resetPosition();
                  }}
                >
                  <FilterCenterFocusRounded />
                </Custom.IconButton>
              </Box>

              {/* Fullscreen Image */}
              <motion.img
                src={src}
                alt={alt}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  cursor: "inherit",
                  objectFit: "contain",
                  transition: dragging ? "none" : "transform 0.2s ease",
                  borderRadius: theme.border.square * theme.border.factor,
                  transform: `scale(${scale}) translate(${translate.x}px, ${translate.y}px)`,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                draggable={false} // Disable default image dragging
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Box>
  );
};
