import { Box } from "@mui/material";
import { Item } from "components";
import { useTheme } from "contexts";
import { ActivityType } from "types";

type Props = {
  items: ActivityType[];
};

export const List = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Box component="div" my={theme.spacing.md}>
      {props.items.map((item: ActivityType, i: number) => (
        <Item.Activity
          key={i}
          item={item}
          head={i === 0}
          tail={i === props.items.length - 1}
        />
      ))}
    </Box>
  );
};
