import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

type SvgBackgroundProps = {
  svgs: React.ReactNode[]; // Array of React components representing SVGs
  count: number; // Number of SVGs to display
  minDistance?: number; // Minimum distance between SVGs (default: 200px)
};

export const Background: React.FC<SvgBackgroundProps> = ({
  svgs,
  count,
  minDistance = 200,
}) => {
  const [currentHeight, setCurrentHeight] = useState<number>(0);
  const [positions, setPositions] = useState<
    { id: number; x: number; y: number; Svg: React.ReactNode }[]
  >([]);

  // useEffect(() => {
  //   const rootElement = document.getElementsByTagName("main")[0];

  //   const placeRandomly = (
  //     existing: { x: number; y: number; width: number; height: number }[],
  //     width: number,
  //     sliceHeight: number,
  //     sliceIndex: number,
  //     svgWidth: number,
  //     svgHeight: number,
  //     domElements: DOMRect[]
  //   ) => {
  //     let x: number, y: number, isValid: boolean;

  //     do {
  //       x = Math.random() * (width - svgWidth); // Random x within bounds
  //       y = Math.random() * sliceHeight + sliceIndex * sliceHeight; // Random y within slice

  //       // Ensure minimum distance from existing SVGs
  //       isValid =
  //         existing.every(
  //           (item) =>
  //             Math.hypot(item.x - x, item.y - y) >=
  //             Math.max(minDistance, item.width, item.height)
  //         ) &&
  //         // Ensure no overlap with DOM elements
  //         domElements.every(
  //           (rect) =>
  //             x + svgWidth < rect.left || // To the left of the DOM element
  //             x > rect.right || // To the right of the DOM element
  //             y + svgHeight < rect.top || // Above the DOM element
  //             y > rect.bottom // Below the DOM element
  //         );
  //     } while (!isValid);

  //     // Ensure SVG stays within bounds
  //     x = Math.min(x, width - svgWidth);
  //     y = Math.min(y, sliceHeight - svgHeight + sliceIndex * sliceHeight);

  //     return { x, y };
  //   };

  //   const generatePositions = () => {
  //     const randomPositions: {
  //       id: number;
  //       x: number;
  //       y: number;
  //       Svg: React.ReactNode;
  //       width: number;
  //       height: number;
  //     }[] = [];

  //     const pageWidth = document.documentElement.scrollWidth;
  //     const pageHeight = document.documentElement.scrollHeight;
  //     const sliceHeight = pageHeight / count;
  //     const svgWidth = 200; // Assumed SVG width
  //     const svgHeight = 200; // Assumed SVG height

  //     // Get bounding boxes of existing DOM elements
  //     const domElements = Array.from(
  //       rootElement.querySelectorAll("section")
  //     ).map((el) => el.getBoundingClientRect());

  //     Array.from({ length: count }, (_, i) => {
  //       const Svg = svgs[Math.floor(Math.random() * svgs.length)];
  //       const { x, y } = placeRandomly(
  //         randomPositions,
  //         pageWidth,
  //         sliceHeight,
  //         i,
  //         svgWidth,
  //         svgHeight,
  //         domElements
  //       );
  //       randomPositions.push({
  //         id: i,
  //         x,
  //         y,
  //         Svg,
  //         width: svgWidth,
  //         height: svgHeight,
  //       });
  //     });

  //     return randomPositions.map(({ id, x, y, Svg }) => ({ id, x, y, Svg }));
  //   };

  //   const onDocumentRefresh = () => setPositions(generatePositions());

  //   // create an Observer instance
  //   const observer = new ResizeObserver((entries) => {
  //     const newHeight = entries[0].contentRect.height;
  //     if (newHeight >= currentHeight * 0.25) {
  //       setCurrentHeight(newHeight);
  //       setTimeout(() => onDocumentRefresh(), 1000);
  //     }
  //   });

  //   const onDocumentReady = () => observer.observe(rootElement);

  //   if (document.readyState === "complete") {
  //     onDocumentReady();
  //   } else {
  //     window.addEventListener("load", onDocumentReady);
  //     return () => {
  //       observer.unobserve(rootElement);
  //       window.removeEventListener("load", onDocumentReady);
  //     };
  //   }
  // }, []);

  return (
    <React.Fragment>
      {/* {positions.map(({ id, x, y, Svg }) => (
        <motion.div
          key={id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          style={{
            position: "absolute",
            transform: `translate(${x}px, ${y}px)`,
          }}
        >
          {Svg}
        </motion.div>
      ))} */}
    </React.Fragment>
  );
};
