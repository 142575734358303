import React from "react";
import { Button as MUIButton, ButtonProps, SxProps } from "@mui/material";
import { useTheme } from "contexts";

// icons
import { ArrowOutwardRounded } from "@mui/icons-material";
import { HValidation } from "helpers";

type Props = Omit<ButtonProps, "type" | "color" | "sx"> & {
  submit?: boolean;
  secondary?: boolean;
  color?: string; // Custom color prop
  bgColor?: string; // Custom background color prop
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: SxProps; // Allow additional styling overrides
};

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      sx = {},
      submit = false,
      secondary = false,
      color,
      bgColor,
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    const { theme } = useTheme();

    const isText = props.variant === "text";
    const isOutlined = props.variant === "outlined";
    const isContained = props.variant === "contained";

    const endIcon =
      props.href && HValidation.isUrl(props.href) ? (
        <ArrowOutwardRounded />
      ) : undefined;

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      if (onClick) onClick(event);
    }

    return (
      <MUIButton
        ref={ref}
        sx={{
          boxShadow: "none",
          textTransform: "none",
          borderRadius: theme.border.radius,
          color: isContained
            ? color || theme.color.accent.text
            : bgColor || theme.color.accent.color,
          backgroundColor: isContained
            ? bgColor || theme.color.accent.color
            : undefined,
          borderColor: isOutlined
            ? bgColor || theme.color.accent.color
            : undefined,
          ...(isContained && {
            color: theme.color.accent.text,
            backgroundColor: theme.color.accent.color,
          }),
          ...(secondary && {
            color: theme.color.secondary.text,
            backgroundColor: theme.color.secondary.color,
          }),
          "&:hover": {
            color: theme.color.font.color,
            boxShadow: "none",
            backgroundColor: theme.color.theme.color,
          },
          ...sx,
        }}
        endIcon={endIcon}
        onClick={handleClick}
        type={submit ? "submit" : "button"}
        {...props}
      >
        {children}
      </MUIButton>
    );
  }
);

Button.displayName = "Button";
