import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMotionValue, useTransform, useSpring } from "framer-motion";
import { Custom } from "components";
import { useTheme } from "contexts";
import { Box, Stack } from "@mui/material";
import { HString } from "helpers";
import {
  AddRounded,
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  AttachMoneyRounded,
  ChevronRightRounded,
  ChevronLeftRounded,
  CloseRounded,
  PercentRounded,
  RemoveRounded,
} from "@mui/icons-material";

type Props = {
  abbr?: boolean;
  prev?: number;
  above?: string;
  below?: string;
  before?: string;
  after?: string;
  count: number; // Final value to count up to
  duration?: number; // Animation duration in seconds
  delay?: number; // Animation delay in seconds
};

const formatNumber = (value: number, abbr: boolean): string => {
  if (!abbr) return value.toLocaleString();
  if (value >= 1_000_000) return `${(value / 1_000_000).toFixed(1)}kk`;
  if (value >= 1_000) return `${(value / 1_000).toFixed(1)}k`;
  return value.toString();
};

export const AnimatedCounter: React.FC<Props> = ({
  count,
  abbr = false,
  duration = 2,
  delay = 0,
  prev,
  above,
  below,
  before,
  after,
}) => {
  const { theme } = useTheme();
  const ref = useRef<HTMLElement | null>(null);
  const motionValue = useMotionValue<number>(0);
  const springValue = useSpring(motionValue, { stiffness: 80, damping: 30 });
  const [formattedValue, setFormattedValue] = useState<string>("");

  const roundedValue = useTransform(springValue, (value) =>
    formatNumber(Math.round(value), abbr)
  );

  useEffect(() => {
    const unsubscribe = roundedValue.onChange(setFormattedValue);
    return () => unsubscribe();
  }, [roundedValue]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Start animation only when the component is visible
          motionValue.set(0); // Reset to 0
          setTimeout(() => {
            motionValue.set(count); // Animate to the target count after the delay
          }, delay * 1000);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef); // Start observing the component
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Clean up observation on unmount
      }
    };
  }, [count, delay, motionValue]);

  const sxColor = { color: theme.color.font.color };
  const sxSize = (size: number) => ({
    width: size,
    height: size,
  });

  const percentage = useMemo(
    () => (prev ? 100 * ((count - prev) / prev) : 0),
    [count, prev]
  );

  const isUp = percentage > 0;
  const textColor = useMemo(
    () =>
      isUp ? theme.color.status.success.color : theme.color.status.error.color,
    [isUp, theme]
  );

  const iconMapper: Record<string, React.ReactNode> = useMemo(
    () => ({
      "+": <AddRounded sx={{ ...sxSize(40), ...sxColor }} />,
      down: <ArrowDownwardRounded sx={{ ...sxSize(40), ...sxColor }} />,
      up: <ArrowUpwardRounded sx={{ ...sxSize(40), ...sxColor }} />,
      $: <AttachMoneyRounded sx={{ ...sxSize(40), ...sxColor }} />,
      ">": <ChevronRightRounded sx={{ ...sxSize(40), ...sxColor }} />,
      "<": <ChevronLeftRounded sx={{ ...sxSize(40), ...sxColor }} />,
      x: <CloseRounded sx={{ ...sxSize(40), ...sxColor }} />,
      "%": <PercentRounded sx={{ ...sxSize(40), ...sxColor }} />,
      "-": <RemoveRounded sx={{ ...sxSize(40), ...sxColor }} />,
    }),
    [theme]
  );

  const renderAboveBelow = (text: string) => (
    <Custom.Typography
      lineHeight={1}
      variant="body2"
      weight={theme.font.medium}
    >
      {text}
    </Custom.Typography>
  );

  const renderBeforeAfter = (text: string) =>
    iconMapper[text] || (
      <Custom.Typography
        lineHeight={1}
        size={theme.font.xxxl}
        weight={theme.font.extraBold}
      >
        {text}
      </Custom.Typography>
    );

  return (
    <Box ref={ref} display="flex" justifyContent="center" textAlign="center">
      <Stack
        gap={theme.spacing.xs}
        justifyContent="center"
        sx={{
          "& .MuiTypography-root": {
            mt: "0 !important",
            mb: "0 !important",
          },
        }}
      >
        {above && renderAboveBelow(above)}
        <Stack direction="row" alignItems="flex-end" justifyContent="center">
          {before && renderBeforeAfter(before)}
          <Custom.Typography
            size={theme.font.xxxl}
            weight={theme.font.extraBold}
            lineHeight={1}
          >
            {formattedValue}
          </Custom.Typography>
          {after && renderBeforeAfter(after)}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={theme.spacing.xs}
        >
          {prev && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing.xxs}
              sx={{
                px: theme.spacing.xs,
                py: theme.spacing.xxs,
                borderRadius: theme.border.radius,
                bgcolor: `rgba(${HString.hexToRgb(textColor).join(",")}, 0.35)`,
                color: textColor,
              }}
            >
              {isUp ? (
                <ArrowUpwardRounded sx={sxSize(12)} />
              ) : (
                <ArrowDownwardRounded sx={sxSize(12)} />
              )}
              <Custom.Typography
                lineHeight={1}
                color={textColor}
                size={theme.font.xxs}
                weight={theme.font.extraBold}
              >
                {+percentage.toFixed(2)}
              </Custom.Typography>
              <PercentRounded sx={sxSize(12)} />
            </Stack>
          )}
          {below && renderAboveBelow(below)}
        </Stack>
      </Stack>
    </Box>
  );
};
