import { Theme } from 'themes/interfaces';
import { createTheme } from '@mui/material/styles';

export const defaultTheme = (theme: Theme) => createTheme({
  direction: "ltr",
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1366,
      xl: 1600,
    }
  },
  palette: {
    mode: "light",
    common: {
      black: theme.color.black,
      white: theme.color.white
    },
    primary: {
      main: theme.color.accent.color,
      contrastText: theme.color.accent.text
    },
    secondary: {
      main: theme.color.secondary.color,
      contrastText: theme.color.secondary.text
    },
    error: {
      main: theme.color.status.error.color,
      contrastText: theme.color.status.error.text
    },
    warning: {
      main: theme.color.status.warning.color,
      contrastText: theme.color.status.warning.text
    },
    success: {
      main: theme.color.status.success.color,
      contrastText: theme.color.status.success.text
    },
    text: {
      primary: theme.color.font.color,
      secondary: theme.color.font.accent,
      disabled: theme.color.disabled.color
    },
    divider: theme.color.border.color,
    background: {
      default: theme.color.background.color
    }
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "\"Space Grotesk\", sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.45,
      letterSpacing: "-0.01562em"
    },
    h2: {
      fontWeight: 300,
      fontSize: 32,
      lineHeight: 1.45,
      letterSpacing: "-0.00833em"
    },
    h3: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: 1.45,
      letterSpacing: "0em",
      marginBottom: 8
    },
    h4: {
      fontWeight: 600,
      fontSize: 22,
      lineHeight: 1.45,
      letterSpacing: "0em"
    },
    h5: {
      fontWeight: 600,
      fontSize: 22,
      lineHeight: 1.45,
      letterSpacing: "0em"
    },
    h6: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.45,
      letterSpacing: "0.0075em"
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em"
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em"
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em"
    },
    body2: {
      fontWeight: 300,
      fontSize: "1rem",
      lineHeight: 1.8,
      letterSpacing: "0.00938em",
      marginBottom: 16
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase"
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em"
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase"
    }
  }
})