import "./index.scss";

import ReactDOM from "react-dom/client";
import Routers from "router";
import { AppProvider, ThemeProvider } from "contexts";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { Themes } from "utils";
import { Light } from "themes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AppProvider initLocale={"EN"}>
    <ThemeProvider initTheme={Light}>
      <MUIThemeProvider theme={Themes.defaultTheme(Light)}>
        <Routers />
      </MUIThemeProvider>
    </ThemeProvider>
  </AppProvider>
);
