import React from "react";
import { Box } from "@mui/material";

type Props = {
  src: string; // URL of the video file
  title?: string; // Title for the video (optional)
  controls?: boolean; // Show video controls (default: true)
  autoPlay?: boolean; // Auto-play the video (default: false)
  loop?: boolean; // Loop the video (default: false)
  muted?: boolean; // Mute the video (default: false)
};

export const Video = ({
  src,
  title = "Video Player",
  controls = true,
  autoPlay = false,
  loop = false,
  muted = false,
}: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        paddingTop: "56.25%", // 16:9 Aspect Ratio
        overflow: "hidden",
      }}
    >
      <Box
        component="video"
        src={src}
        controls={controls}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        title={title}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the video fits the container
        }}
      />
    </Box>
  );
};
