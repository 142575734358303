import { Box } from "@mui/material";
import { Custom } from "components";
import { useTheme } from "contexts";

type Props = {
  title: string;
};

export const Header = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Box component="div" width={1} mb={theme.spacing.xl}>
      <Box
        component="div"
        sx={{
          position: "relative",
          alignItems: "center",
          display: "inline-flex",
          color: theme.color.font.color,
          "&::after": {
            right: 0,
            bottom: 3,
            height: 3,
            width: "50%",
            content: "''",
            position: "absolute",
            borderRadius: theme.border.circle,
            backgroundColor: theme.color.font.color,
          },
        }}
      >
        <Custom.Typography size={theme.font.xl} weight={theme.font.bold}>
          {props.title}
        </Custom.Typography>
      </Box>
    </Box>
  );
};
