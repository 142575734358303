/* eslint-disable quotes */
import type { Color, ColorContrast, ColorSchema, Theme } from "themes/interfaces";
import { BaseBorder, BaseColor, BaseComponent, BaseFont, BaseSpacing } from "themes/base";
import { Enums } from "utils";

export const Dark: Theme = {
  id: Enums.EnumTheme.Dark,
  font: BaseFont,
  color: {
    ...BaseColor,
    theme: { color: "#202020" } as ColorSchema,
    border: { color: "#505050", accent: "#808080" } as ColorContrast,
    shadow: { color: "0 3px 10px 0 #D0D0D0" } as ColorContrast,
    overlay: { color: "rgba(0, 0, 0, 0.5)" } as ColorContrast,
    input: { color: "#202020", accent: "#DBDBDB" } as ColorSchema,
    font: { color: "#FFFFFF", accent: "#C8C8C8" } as ColorContrast,
    background: { color: "#101010", accent: "#272727", textAccent: "#404040" } as ColorSchema,
    primary: { color: "#B7E2DC", text: "#404040", accent: "#202020", textAccent: "#FFFFFF" } as ColorSchema,
    accent: { color: "#79C6BB", text: "#FFFFFF" } as ColorSchema,
    secondary: { color: "#272727", text: "#FFFFFF" } as ColorSchema,
    disabled: { color: "#303030", text: "#A6A6A6" } as ColorSchema,
  } as Color,
  border: BaseBorder,
  spacing: BaseSpacing,
  component: BaseComponent,
};
