import { ReactNode, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from "contexts";
import { Custom, Vector } from "components";
import { Constants } from "utils";

// icons
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";

type Props = {
  to?: string;
  from?: string;
  text: string;
  expanded: boolean;
  children?: ReactNode;
};

export const Timeline = (props: Props) => {
  const { theme } = useTheme();

  const [isExpanded, setIsExpanded] = useState<boolean>(props.expanded);

  const iconStyle = { width: 32, height: 32, fill: theme.color.font.color };

  function handleToggle() {
    setIsExpanded((prev: boolean) => !prev);
  }

  return (
    <Box className="timeline-year-group">
      <Box
        onClick={handleToggle}
        bgcolor={theme.color.background.color}
        className={
          props.children
            ? "timeline-year c-pointer"
            : "timeline-year no-pointer"
        }
      >
        <Box className="timeline-icon">
          <Vector.Icon name="version" fill={theme.color.font.color} />
        </Box>
        <Custom.Typography size={theme.font.sm} weight={theme.font.bold}>
          {props.from
            ? `${props.from} ${Constants.SEPARATOR} ${props.to ?? "Present"}: `
            : ""}
          <Custom.Typography sx={{ opacity: 0.7 }}>
            {props.text}
          </Custom.Typography>
        </Custom.Typography>
        {props.children && (
          <Custom.IconButton sx={{ ml: "auto" }}>
            {isExpanded ? (
              <ExpandLessRounded sx={iconStyle} />
            ) : (
              <ExpandMoreRounded sx={iconStyle} />
            )}
          </Custom.IconButton>
        )}
      </Box>
      {isExpanded && <Box className="timeline-content">{props.children}</Box>}
    </Box>
  );
};
