import { Box } from "@mui/material";
import { Custom } from "components";
import { useTheme } from "contexts";

type Props = {
  content: string;
};

export const HighlightedBlock = ({ content }: Props) => {
  const { theme } = useTheme();

  return (
    <Box
      component="div"
      sx={{
        borderLeft: 5,
        lineHeight: 1.5,
        my: theme.spacing.md,
        px: theme.spacing.md,
        py: theme.spacing.xxs,
        color: theme.color.font.color,
        borderLeftColor: theme.color.accent.color,
      }}
    >
      <Custom.Typography variant="body1">{content}</Custom.Typography>
    </Box>
  );
};
