import { Box, Stack } from "@mui/material";
import { Common, Custom, Item, Shared, Vector } from "components";

export const mapper: Record<string, React.ElementType> = {
  AnimatedCounter: Shared.AnimatedCounter,
  Box,
  BulletPoint: Shared.BulletPoint,
  Button: Custom.Button,
  Divider: Shared.Divider,
  DoDont: Shared.DoDont,
  Embedded: Shared.Embedded,
  Footnote: Shared.Footnote,
  Gallery: Shared.Gallery,
  Grid: Custom.Grid,
  Header: Shared.Header,
  HighlightedBlock: Shared.HighlightedBlock,
  HighlightedBox: Shared.HighlightedBox,
  HorizontalLine: Shared.HorizontalLine,
  Icon: Vector.Icon,
  Image: Shared.Image,
  IndexerItem: Item.Indexer,
  List: Shared.List,
  ListItem: Item.List,
  Navigation: Common.Navigation,
  Section: Common.Section,
  Social: Common.Social,
  Stack,
  Quote: Shared.Quote,
  Video: Shared.Video,
  Timeline: Shared.Timeline,
  Typography: Custom.Typography,
};