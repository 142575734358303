import { ConfigType, FilterType, ComponentType, SectionType, ServiceType, SocialType, WorkType } from "types";

import About from "assets/data/about.json";
import Config from "assets/data/config.json";
import Header from "assets/data/header.json";
import Section from "assets/data/section.json";
import Service from "assets/data/service.json";
import Social from "assets/data/social.json";
import Work from "assets/data/work.json";
import WorkFilter from "assets/data/filter.json";
import { HeaderType } from "types/header";

export const AboutConfig = About as unknown as ComponentType[];
export const GeneralConfig = Config as unknown as ConfigType;
export const HeaderConfig = Header as unknown as HeaderType;
export const SectionConfig = Section as unknown as SectionType;
export const ServiceConfig = Service as unknown as ServiceType;
export const SocialConfig = Social as unknown as SocialType;
export const WorkConfig = Work as unknown as WorkType;
export const WorkFilterConfig = WorkFilter as unknown as FilterType;