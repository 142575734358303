import React, { createContext, useContext } from "react";
import { Theme } from "themes/interfaces";
import { Dark, Light } from "themes";
import { HObject } from "helpers";
import { EnumLayout } from "utils/enums";

type ThemeProps = {
  theme: Theme;
  isDark: boolean;
  layout: EnumLayout;
  setLayout(layout: EnumLayout): void;
  setTheme: (theme: Theme) => void;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeProps>({
  theme: Light,
  isDark: false,
  layout: EnumLayout.Default,
  setTheme: () => {},
  setLayout: () => {},
  toggleTheme: () => {},
});

interface Props {
  initTheme: Theme;
  children?: React.ReactNode;
}

export const ThemeProvider = React.memo<Props>(
  ({ initTheme, children }: Props) => {
    const [theme, setTheme] = React.useState<Theme>(initTheme);
    const [layout, setLayout] = React.useState<EnumLayout>(EnumLayout.Default);

    const isDark = theme.id === Dark.id;

    const setThemeCallback = React.useCallback((newTheme: Theme) => {
      setTheme((currentTheme: Theme) =>
        HObject.deepInterpolation(currentTheme, newTheme)
      );
    }, []);

    const toggleThemeCallback = React.useCallback(() => {
      setTheme((currentTheme: Theme) =>
        currentTheme.id === Dark.id ? Light : Dark
      );
    }, []);

    const MemoizedValue = React.useMemo(() => {
      const value: ThemeProps = {
        theme,
        isDark,
        layout,
        setLayout,
        setTheme: setThemeCallback,
        toggleTheme: toggleThemeCallback,
      };
      return value;
    }, [
      layout,
      isDark,
      setLayout,
      setThemeCallback,
      theme,
      toggleThemeCallback,
    ]);

    return (
      <ThemeContext.Provider value={MemoizedValue}>
        {children}
      </ThemeContext.Provider>
    );
  }
);

export const useTheme = () => useContext(ThemeContext);
