import React from "react";
import {
  IconButton as MUIIconButton,
  IconButtonProps,
  SxProps,
} from "@mui/material";
import { useTheme } from "contexts";

type Props = Omit<IconButtonProps, "sx"> & {
  children: React.ReactNode;
  foreground?: string; // Optional foreground color for custom color
  sx?: SxProps; // Allow additional styling overrides
};

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, foreground, sx, ...props }, ref) => {
    const { theme } = useTheme();

    // Define the custom styles
    const iconButtonStyles: SxProps = {
      color: foreground ?? theme.color.font.color, // Default to theme color or custom foreground
      ...sx, // Allow overriding styles with sx prop
    };

    return (
      <MUIIconButton {...props} sx={iconButtonStyles} ref={ref}>
        {children}
      </MUIIconButton>
    );
  }
);
