import { Box } from "@mui/material";
import { Vector } from "components";
import { useTheme } from "contexts";

type Props = {
  justify?: string;
};

export const Divider = ({ justify = "center" }: Props) => {
  const { theme } = useTheme();

  return (
    // <Box
    //   width={1}
    //   display="flex"
    //   my={theme.spacing.xl}
    //   justifyContent={justify}
    // >
    //   <Vector.Image name="divider" />
    // </Box>
    <></>
  );
};
