import { Box, Grid, Stack } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Common, Custom, Shared } from "components";
import { GeneralConfig } from "assets/data";

type Props = {};

export const Intro = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Common.Section id="intro" sx={{ mt: theme.component.layout.mt.default }}>
      <Box className="content">
        <Grid container spacing={theme.spacing.xxxl}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Stack spacing={theme.spacing.lg}>
              <Custom.Typography variant="h1">
                {t.intro.header}
              </Custom.Typography>
              <Custom.Typography
                variant="h2"
                weight={theme.font.normal}
                color={theme.color.font.accent}
              >
                {t.intro.headline}
              </Custom.Typography>
              <Shared.Tabs tabs={t.intro.tabs} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Box
              width={1}
              component="img"
              borderRadius="50%"
              src="/assets/user.png"
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            order={{ xs: 3 }}
          >
            <Common.Social items={GeneralConfig["home"].social} />
          </Grid>
        </Grid>
      </Box>
    </Common.Section>
  );
};
