import "assets/scss/layouts/project.scss";

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useTheme } from "contexts";
import { ThemeProvider } from "@mui/material/styles";
import { Themes } from "utils";
import { Common } from "components";
import { useEffect } from "react";
import { EnumLayout } from "utils/enums";
import { ArrowBack, ArrowRight, ArrowUp } from "assets/images";

export const ProjectLayout = () => {
  const { isDark, setLayout, theme } = useTheme();

  useEffect(() => {
    setLayout(EnumLayout.Project);
  }, []);

  return (
    <ThemeProvider theme={Themes.defaultTheme(theme)}>
      <Box
        component="main"
        bgcolor={theme.color.background.color}
        sx={{
          backgroundImage: isDark
            ? `url(/assets/bg_dark.svg)`
            : `url(/assets/bg_light.svg)`,
        }}
      >
        <Common.Background
          count={8}
          svgs={[
            <ArrowBack color={theme.color.primary.color} />,
            <ArrowRight color={theme.color.primary.color} />,
            <ArrowUp color={theme.color.primary.color} />,
          ]}
        />
        <Common.Header />
        <Common.Navbar />
        <Box className="core-container">
          <Outlet />
        </Box>
        <Common.Footer />
      </Box>
    </ThemeProvider>
  );
};
