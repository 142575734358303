import { motion } from "framer-motion";
import { Box, Stack } from "@mui/material";
import { Common, Custom } from "components";
import { useTheme } from "contexts";
import { GeneralConfig, SectionConfig } from "assets/data";
import { useNavigate, useLocation } from "react-router-dom";
import { HObject } from "helpers";

// icons
import { CloseRounded } from "@mui/icons-material";

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const Sidebar = ({ opened = false, onClose }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { theme } = useTheme();

  function handleNavigate(key: string) {
    navigate(SectionConfig[key].href);
    onClose();
  }

  return (
    <>
      {opened && (
        <Box
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            position: "fixed",
            cursor: "pointer",
            backgroundColor: theme.color.overlay.color,
          }}
          onClick={onClose}
        />
      )}

      <motion.div
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 9999,
          width: "70%",
          maxWidth: 500,
          position: "fixed",
        }}
        initial={{ x: "-100%" }}
        animate={{ x: opened ? 0 : "-100%" }}
        transition={{ type: "tween", duration: 0.3 }}
        drag="x"
        dragElastic={0.1}
        dragConstraints={{ left: 0, right: 0 }}
        onDrag={(event, info) => {
          if (info.offset.x > 0) {
            event.preventDefault();
          }
        }}
        onDragEnd={(event, info) => {
          if (info.offset.x < -50) {
            onClose();
          }
        }}
      >
        {opened && (
          <Stack
            sx={{
              height: 1,
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing.md,
              backgroundColor: theme.color.background.accent,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Common.Logo color={theme.color.font.color} />
              <Custom.IconButton
                size="large"
                onClick={onClose}
                foreground={theme.color.font.color}
              >
                <CloseRounded />
              </Custom.IconButton>
            </Stack>

            <Stack
              alignItems="center"
              mt={theme.spacing.xxxl}
              spacing={theme.spacing.md}
            >
              {Object.keys(SectionConfig).map((key: string, i: number) => (
                <Custom.Typography
                  key={i}
                  variant="h2"
                  className="c-pointer"
                  onClick={() => handleNavigate(key)}
                  weight={
                    location.pathname === SectionConfig[key].href
                      ? theme.font.black
                      : theme.font.normal
                  }
                >
                  {HObject.getProperty(SectionConfig, [key, "name"])}
                </Custom.Typography>
              ))}
            </Stack>

            <Stack
              mt="auto"
              alignItems="center"
              mb={theme.spacing.md}
              spacing={theme.spacing.xxxl}
            >
              <Common.Social
                items={HObject.getProperty(GeneralConfig, ["home", "social"])}
              />
              <Common.Signature center />
            </Stack>
          </Stack>
        )}
      </motion.div>
    </>
  );
};
