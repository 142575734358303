import { Paper } from "@mui/material";
import { Common, Page, Shared } from "components";
import { useTheme } from "contexts";
import { IndexerContext, SectionProvider } from "contexts/components";
import { useEffect, useState } from "react";
import { ProjectType } from "types";

type Props = {
  name: string;
  project?: ProjectType;
  onLoad?: (project: ProjectType) => void;
};

export const Project = (props: Props) => {
  const { theme } = useTheme();

  const [indexes, setIndexes] = useState<string[]>([]);
  const [project, setProject] = useState<ProjectType | null>(null);

  useEffect(() => {
    const loadProject = async () => {
      try {
        const response = await fetch(`/projects/${props.name}.json`);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${props.name}.json`);
        }
        const data: ProjectType = await response.json();
        setProject(data);
        props.onLoad && props.onLoad(data);
      } catch (error) {
        console.error(error);
      }
    };
    loadProject();
  }, [props.name]);

  useEffect(() => {
    setProject(props.project || null);
  }, [props.project]);

  function registerIndex(id: string) {
    setIndexes((prev) => [...new Set([...prev, id])]);
  }

  if (!project) {
    return <Page.Loader />;
  }

  return (
    <SectionProvider>
      <IndexerContext.Provider value={{ registerIndex, indexes }}>
        <Common.Section
          id="project"
          sx={{ textAlign: "center", mb: theme.spacing.xl }}
        >
          <Paper
            elevation={4}
            component="img"
            src={`/assets/${props.name}/banner.png`}
            sx={{
              width: 1,
              height: {
                md: 500,
              },
              maxWidth: 960,
              display: "block",
              margin: "0 auto",
              mb: theme.spacing.xl,
              mt: -theme.spacing.default * 0.8,
              borderRadius: theme.border.square,
              // boxShadow: theme.color.shadow.color,
            }}
          />
        </Common.Section>
        <Common.Navigation />
        <Shared.DynamicRenderer config={project.components} />
      </IndexerContext.Provider>
    </SectionProvider>
  );
};
