import { Box, CircularProgress, keyframes } from "@mui/material";
import { Custom } from "components";
import { useApp, useTheme } from "contexts";

const dotsAnimation = keyframes`
  0% { content: ''; }
  25% { content: '.'; }
  50% { content: '..'; }
  75% { content: '...'; }
`;

export const Loader = () => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box
      sx={{
        top: "50%",
        left: "50%",
        textAlign: "center",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress
        thickness={4}
        color="primary"
        size={theme.spacing.default}
        sx={{ mb: theme.spacing.sm }}
      />

      <Custom.Typography
        variant="h6"
        component="div"
        sx={{
          "&::after": {
            content: "''",
            display: "inline-block",
            animation: `${dotsAnimation} 2s infinite steps(1)`,
          },
        }}
      >
        {t.message.loading}
      </Custom.Typography>
    </Box>
  );
};
