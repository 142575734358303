import { en } from "assets/locales"

export const REGIONS = {
  EN: "EN"
};

export const LOCALE_STRINGS = {
  [REGIONS.EN]: en
};

export const DATE_FORMAT = {
  DEFAULT: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  YEAR_MONTH: {
    year: "numeric",
    month: "short",
  }
}

export const TIME_FORMAT = {
  DEFAULT: {
    hour: "2-digit",
    minute: "2-digit",
  }
}

export const INPUT_MASK = {
  DATE: "9999-99-99",
  PHONE: "(999) 999-9999"
}

export const TEXTAREA = {
  MAX: 5,
  MIN: 3
};

export const MAX_INTEGER = 2147483647;
export const SEPARATOR = " — ";