import "assets/scss/pages/home.scss";

import { Stack } from "@mui/material";
import { HeaderConfig, ServiceConfig } from "assets/data";
import { Common, Shared } from "components";
import { useTheme } from "contexts";
import { Routes } from "utils";

type Props = {};

export const Home = (props: Props) => {
  const { theme } = useTheme();

  const sorted = Object.keys(ServiceConfig).sort(
    (a, b) => ServiceConfig[a].order - ServiceConfig[b].order
  );

  return (
    <>
      <Common.Intro />
      <Shared.HorizontalLine />
      <Common.Section id="portfolio">
        <Shared.Portfolio href={Routes.work} />
      </Common.Section>
      <Shared.Footnote>
        <Common.Section id="footnote">
          <Shared.Header title={HeaderConfig.home.footnote.service} />
          <Stack direction="row" flexWrap="wrap" gap={theme.spacing.xl}>
            {sorted.map((key: string) => (
              <Shared.Service
                key={key}
                left
                icon={key}
                abbr={false}
                size="large"
                colored={false}
                item={ServiceConfig[key]}
              />
            ))}
          </Stack>
        </Common.Section>
      </Shared.Footnote>
    </>
  );
};
