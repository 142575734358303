import { Box, Stack } from "@mui/material";
import { Custom, Vector } from "components";
import { useTheme } from "contexts";
import { ServiceObjType } from "types";

type Props = {
  icon: string;
  abbr?: boolean;
  left?: boolean;
  colored?: boolean;
  item: ServiceObjType;
  size?: "small" | "medium" | "large";
};

export const Service = ({
  abbr = true,
  left = false,
  colored = true,
  size = "medium",
  ...props
}: Props) => {
  const { theme, isDark } = useTheme();

  const fontSize =
    {
      small: theme.font.xs,
      medium: theme.font.sm,
      large: theme.font.md,
    }[size] || 24;

  return (
    <Box>
      <Stack direction="row" gap={theme.spacing.sm} alignItems="center">
        <Custom.Typography
          size={fontSize}
          whiteSpace="nowrap"
          order={left ? 1 : 0}
          weight={theme.font.normal}
          color={colored && !isDark ? props.item.color : theme.color.font.color}
        >
          {abbr ? props.item.abbr : props.item.name}
        </Custom.Typography>
        <Vector.Icon
          size={fontSize}
          name={props.icon}
          fill={colored && !isDark ? props.item.color : theme.color.font.color}
        />
      </Stack>
    </Box>
  );
};
