import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useTheme } from "contexts";
import { Common } from "components";
import { useEffect } from "react";
import { EnumLayout } from "utils/enums";
import { ArrowBack, ArrowRight, ArrowUp } from "assets/images";

export const PageLayout = () => {
  const { isDark, setLayout, theme } = useTheme();

  useEffect(() => {
    setLayout(EnumLayout.Default);
  }, []);

  return (
    <Box
      component="main"
      bgcolor={theme.color.background.color}
      sx={{
        backgroundImage: isDark
          ? `url(/assets/bg_dark.svg)`
          : `url(/assets/bg_light.svg)`,
      }}
    >
      <Common.Background
        count={4}
        svgs={[
          <ArrowBack color={theme.color.primary.color} />,
          <ArrowRight color={theme.color.primary.color} />,
          <ArrowUp color={theme.color.primary.color} />,
        ]}
      />
      <Common.Header />
      <Common.Navbar />
      <Box className="core-container">
        <Outlet />
      </Box>
      <Common.Footer />
    </Box>
  );
};
