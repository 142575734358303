import { Box, Stack } from "@mui/material";
import { Common } from "components";
import { useTheme } from "contexts";
import { GeneralConfig } from "assets/data";
import { HObject } from "helpers";

type Props = {};

export const Footer = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Box
      display="flex"
      borderTop={1}
      minHeight={90}
      component="footer"
      borderColor={theme.color.background.accent}
    >
      <Common.Section id="footer">
        <Stack
          height={1}
          direction="row"
          alignItems="center"
          pt={theme.spacing.xl}
          pb={theme.spacing.md}
          px={theme.spacing.xl}
          gap={theme.spacing.xs}
          flexWrap={{ xs: "wrap" }}
          className="section-container"
          justifyContent="space-between"
        >
          <Common.Signature />
          <Common.Social
            size="medium"
            items={HObject.getProperty(GeneralConfig, ["home", "social"])}
          />
        </Stack>
      </Common.Section>
    </Box>
  );
};
