import { Box, SxProps } from "@mui/material";
import { BrandDark, BrandLight } from "assets/images";
import { useTheme } from "contexts";
import { useNavigate } from "react-router-dom";
import { Routes } from "utils";

type Props = {
  sx?: SxProps;
  color?: string;
};

export const Logo = (props: Props) => {
  const navigate = useNavigate();
  const { isDark } = useTheme();

  return (
    <Box
      sx={{
        width: {
          xs: 1,
          md: "auto",
        },
        mr: {
          xs: "auto",
          md: "unset",
        },
        ml: {
          xs: "auto",
          md: "unset",
        },
        ...props.sx,
      }}
      className="c-pointer"
      onClick={() => navigate(Routes.home)}
    >
      {isDark ? (
        <BrandLight width={48} height={48} />
      ) : (
        <BrandDark width={48} height={48} />
      )}
    </Box>
  );
};
