import { Box } from "@mui/material";
import { useTheme } from "contexts";

type Props = {};

export const Header = (props: Props) => {
  const { isDark, theme } = useTheme();

  return (
    <Box
      component="div"
      sx={{
        mb: 8,
        position: "relative",
        height: theme.component.layout.height.page,
        backgroundColor: theme.color.background.accent,
      }}
    >
      <Box
        sx={{
          height: 1,
          backgroundSize: "cover",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
          backgroundClip: "content-box",
          backgroundImage: isDark
            ? "url(/assets/hero_banner_dark.png)"
            : "url(/assets/hero_banner_light.png)",
          boxShadow: "inset 0px 8px 6px 0px rgba(0, 0, 0, 0.25)",
        }}
      />
    </Box>
  );
};
