import React from "react";
import { Components } from "utils";
import { ComponentType } from "types";

const voidElements = ["img", "input", "br", "hr", "meta", "link"];

export const DynamicRenderer: React.FC<{ config: ComponentType[] }> = ({
  config,
}) => {
  const renderComponent = (componentConfig: ComponentType) => {
    const { component, props = {}, items = [] } = componentConfig;

    // Look up the component in the registry
    const Component = Components.mapper[component];
    if (!Component) {
      console.error(`Component "${component}" is not registered.`);
      return null;
    }

    // Check if the component is a void element
    if (voidElements.includes(props.component)) {
      return <Component {...props} />;
    }

    // Recursively render children if props.children is an object
    let renderedChildren = props.children;
    if (typeof props.children === "object" && !Array.isArray(props.children)) {
      renderedChildren = renderComponent(props.children);
    }

    // Render the component, and recursively render items
    return items.length > 0 ? (
      <Component {...props}>
        {items.map((child, index) => {
          return (
            <React.Fragment key={index}>
              {renderComponent(child)}
            </React.Fragment>
          );
        })}
        {renderedChildren}
      </Component>
    ) : (
      <Component {...props}>{renderedChildren}</Component>
    );
  };

  return (
    <>
      {config.map((item, index) => (
        <React.Fragment key={index}>{renderComponent(item)}</React.Fragment>
      ))}
    </>
  );
};
