import { Component } from 'themes/interfaces';

export default {
  layout: {
    mt: {
      default: 14,
    },
    height: {
      page: 300,
    },
    section: {
      default: {
        xs: 960,
        md: "100%",
        lg: "70%",
        xl: "60%",
      },
      project: {
        xs: 960,
        md: "100%",
        lg: "70%",
        xl: "50%",
      }
    }
  }

} as Component;
