/* eslint-disable quotes */
import {
  Color,
  ColorSchema,
  ColorStatus,
} from "themes/interfaces";

export default {
  status: {
    warning: { color: "#FEDB60", text: "#404040" } as ColorSchema,
    success: { color: "#61B3A8", text: "#ffffff" } as ColorSchema, // #57A75B
    error: { color: "#CF4F4F", text: "#ffffff" } as ColorSchema, // "#E15858"
  } as ColorStatus,
  black: "#222222",
  white: "#E8E8E8"
} as Color;
