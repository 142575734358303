import { useState } from "react";
import { AppBar, Box, Toolbar, Stack, SxProps } from "@mui/material";
import { Common, Custom } from "components";
import { useTheme } from "contexts";
import { SectionConfig } from "assets/data";
import { useNavigate, useLocation } from "react-router-dom";
import { HObject } from "helpers";

// icons
import { DarkModeRounded, Menu as MenuIcon } from "@mui/icons-material";

type Props = {
  sx?: SxProps;
};

export const Navbar = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { theme, toggleTheme } = useTheme();

  const [opened, setOpened] = useState<boolean>(false);

  function handleThemeClick() {
    toggleTheme();
  }

  return (
    <>
      <AppBar
        sx={{
          boxShadow: 0,
          py: theme.spacing.sm,
          backdropFilter: "blur(4px)",
          backgroundColor: "transparent",
          ...props.sx,
        }}
      >
        <Toolbar>
          <Box display={{ xs: "initial", md: "none" }}>
            <Custom.IconButton
              size="large"
              onClick={() => setOpened(true)}
              foreground={theme.color.font.color}
            >
              <MenuIcon />
            </Custom.IconButton>
          </Box>
          <Common.Logo
            key="logo_navbar"
            color={theme.color.font.color}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          />
          <Stack
            ml="auto"
            direction="row"
            alignItems="center"
            spacing={theme.spacing.sm}
          >
            <Box display={{ xs: "none", md: "initial" }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={theme.spacing.sm}
              >
                {Object.keys(SectionConfig).map((key: string, i: number) => (
                  <Custom.Button
                    key={i}
                    size="large"
                    variant="text"
                    sx={{
                      fontSize: theme.font.md,
                      color: theme.color.font.color,
                      ...(location.pathname.includes(SectionConfig[key].href)
                        ? {
                            pointerEvents: key === "about" ? "none" : undefined, // TODO : temp solution
                            textUnderlineOffset: 10,
                            textDecorationThickness: 3,
                            textDecorationLine: "underline",
                          }
                        : {}),
                      "&:hover": {
                        color: theme.color.font.color,
                      },
                    }}
                    onClick={() => navigate(SectionConfig[key].href)}
                  >
                    {HObject.getProperty(SectionConfig, [key, "name"])}
                  </Custom.Button>
                ))}
              </Stack>
            </Box>

            <Custom.IconButton
              size="large"
              onClick={handleThemeClick}
              foreground={theme.color.font.color}
            >
              <DarkModeRounded />
            </Custom.IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Common.Sidebar opened={opened} onClose={() => setOpened(false)} />
    </>
  );
};
