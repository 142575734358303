import "assets/scss/pages/home.scss";

import { Common } from "components";
import { useApp } from "contexts";
import { WorkConfig } from "assets/data";
import { useParams } from "react-router-dom";

type Props = {};

export const Project = (props: Props) => {
  const { isAuthenticated } = useApp();
  const { projectName = "" } = useParams();

  const isProtected =
    Object.values(WorkConfig).filter((x) => x.href === projectName)[0]
      ?.protected ?? false;

  if (isProtected && !isAuthenticated) {
    return <Common.Protected />;
  }

  return <Common.Project name={projectName} />;
};
