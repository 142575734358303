import "assets/scss/pages/home.scss";

import { Common } from "components";

type Props = {};

export const About = (props: Props) => {
  return (
    <>
      <Common.About />
    </>
  );
};
