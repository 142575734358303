import { Box, Grid, Stack } from "@mui/material";
import { Custom } from "components";
import { useApp, useTheme } from "contexts";
import { useForm } from "hooks";
import { SessionType } from "types";

export const Protected = () => {
  const { theme } = useTheme();
  const { setSession, t } = useApp();

  const initialState = { password: "" };

  const { onChange, onSubmit, values } = useForm(
    initialState,
    undefined,
    submitCallback
  );

  async function submitCallback() {
    const { password }: any = values;
    if (password === "qwer1234") {
      setSession({ isAuthenticated: true } as SessionType);
    }
  }

  return (
    <Stack
      mb={0}
      mx="auto"
      mt={theme.spacing.xxxl}
      maxWidth={{ md: "50%" }}
      spacing={theme.spacing.md}
    >
      <Custom.Typography component="p">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Custom.Typography>
      <Box component="form" onSubmit={onSubmit}>
        <Grid container spacing={theme.spacing.md}>
          <Grid item xs={12}>
            <Custom.TextField
              name="password"
              type="password"
              required={true}
              onChange={onChange}
              label={t.label.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Custom.Button fullWidth color="primary" submit>
              {t.action.submit}
            </Custom.Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
