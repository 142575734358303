import { Common, Form } from "components";

type Props = {};

export const Protected = (props: Props) => {
  return (
    <Common.Section id="protected">
      <Form.Protected />
    </Common.Section>
  );
};
