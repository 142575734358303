import React from "react";
import { Box, Stack, SxProps, Paper } from "@mui/material";
import { useTheme } from "contexts";
import { Custom } from "components";

import { CancelRounded, CheckCircleRounded } from "@mui/icons-material";

type Props = {
  sx?: SxProps;
  src: string;
  isDo: boolean;
  position?: string;
  subheader: string;
  children: React.ReactNode;
};

export const DoDont = ({
  src,
  isDo,
  subheader,
  children,
  sx,
  position,
}: Props) => {
  const { theme } = useTheme();

  const borderColor = isDo
    ? theme.color.status.success.color
    : theme.color.status.error.color;

  const iconSx = { width: 40, height: 40 };

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "unset",
        ...sx,
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          width: 1,
          height: 480,
          borderRadius: 2,
          position: "relative",
          borderBottomWidth: 12,
          px: theme.spacing.xxxl,
          borderBottomStyle: "solid",
          borderBottomColor: borderColor,
          backgroundColor: theme.color.black,
        }}
      >
        <Box
          src={src}
          component="img"
          sx={{
            width: 1,
            height: 420,
            objectFit: "contain",
            objectPosition: position,
          }}
        />
        <Box
          sx={{
            lineHeight: 0,
            color: borderColor,
            position: "absolute",
            top: theme.spacing.default * 0.5,
            left: theme.spacing.default * 0.5,
          }}
        >
          {isDo ? (
            <CheckCircleRounded sx={iconSx} />
          ) : (
            <CancelRounded sx={iconSx} />
          )}
        </Box>
      </Stack>

      <Box p={theme.spacing.sm}>
        <Custom.Typography variant="body2">{subheader}</Custom.Typography>
        {children}
      </Box>
    </Paper>
  );
};
